import styled from '@emotion/styled'
import { graphql, Link, useStaticQuery } from 'gatsby'
import React from 'react'
import HomeHeaderBis from '~/components/Home/HomeHeaderBis'
import SEO from '~/components/seo'
import ProductGrid from '../components/ProductGrid'
import { breakpoints } from '../utils/styles'
import Block0 from './../components/Home/Block0'
import Block1 from './../components/Home/Block1'
import Block2 from './../components/Home/Block2'
import Block3 from './../components/Home/Block3'
import Block4 from './../components/Home/Block4'

const IndexPage = () => {
  const image = useStaticQuery(graphql`
    query {
      background: file(
        relativePath: { eq: "images/home-page/large-background.png" }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Container background={image.background.childImageSharp.fluid.src}>
      <SEO title='Home' keywords={['gatsby', 'application', 'react']} />
      <HomeHeaderBis />
      <Block0 />
      <Block1 />
      <Block2 />
      <ProductGrid isHomePage collection='Nos cafés' />
      <ProductsInfo>
        Voir l’intégralité de nos cafés
      </ProductsInfo>
      <Button to='/e-shop'>Le E-Shop</Button>
      <Block3 />
      <Block4 />
    </Container>
  )
}

export default IndexPage

export const Container = styled.div`
  @media (min-width: ${breakpoints.l}px) {
    background-image: url('${props => props.background}');
    background-repeat: no-repeat;
    background-position: right 17%;
    background-size: 55%;
  }
`

export const ProductsInfo = styled.p`
  display: block;
  padding: 0 .5em;
  font-size: 1.1em;
  text-align: center;
  margin-top: 0.5em;
  margin-bottom: 0;
  color: #2b5c6b;
`

export const Button = styled(Link)`
  margin: 1em auto 0 auto;
  color: #12282e;
  padding: 0.3em 2em;
  background-color: transparent;
  border: 2px solid #12282e;
  border-radius: 30px;
  font-size: 1.2em;
  display: block;
  width: fit-content;
  text-decoration: none;
  text-align: center;

  :hover {
    cursor: pointer;
    background-color: #12282e;
    border: 2px solid transparent;
    color: white;
  }

  @media (max-width: ${breakpoints.s}px) {
    margin: 1em auto 1em auto;
    width: 50%;
  }
`
