import styled from '@emotion/styled'
import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import React from 'react'
import { breakpoints } from '../../utils/styles'

const HomeHeaderBis = () => {
  const images = useStaticQuery(graphql`
    query {
      cofee: file(
        relativePath: { eq: "images/home-page/header/cofee-cup-1.png" }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }

      hamburger: file(relativePath: { eq: "images/home-page/hamburger.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }

      backgroundLeft: file(
        relativePath: { eq: "images/home-page/header/background-left.png" }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }

      backgroundRight: file(
        relativePath: { eq: "images/home-page/header/background-right.png" }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Container
      backgroundLeft={images.backgroundLeft.childImageSharp.fluid.src}
      backgroundRight={images.backgroundRight.childImageSharp.fluid.src}
    >
      <LeftBox
        data-aos='fade-right'
        data-aos-duration='2000'
      >
        <TitleBox>
          <Title left>
            Latte
            <br />
            <span>Matcha</span>
          </Title>
          <SubTitle>
            Lait et Infusion de thé
            <br /> matcha <span>Bio</span>
          </SubTitle>
        </TitleBox>
        <Button
          href='https://jhnco-clickncollect.fr/produit/latte-matcha'
          style={{ marginBottom: '2em' }}
        >
          Découvrir
        </Button>
        <Image
          fluid={images.cofee.childImageSharp.fluid}
        />
      </LeftBox>

      <CenterBox>
        <Separator />
        <a
          href='https://jhnco-clickncollect.fr'
          style={{ textDecoration: 'none' }}
        >
          <CircleBox>
            <p>Click’n’ Collect</p>
          </CircleBox>
        </a>
        <Separator />
      </CenterBox>

      <RightBox
        data-aos='fade-left'
        data-aos-duration='3000'
        data-aos-delay='400'
      >
        <TitleBox>
          <Title>
            Egg Muffin
            <br />
            <span>Original</span>
          </Title>
          <SubTitle>
            Pain maison, Oeuf <span>Bio</span> <br />& Cheddar Anglais
          </SubTitle>
        </TitleBox>
        <Button
          style={{ marginBottom: '3em' }}
          href='https://jhnco-clickncollect.fr/produit/egg-muffin-original'
        >
          Découvrir
        </Button>
        <Image style={{ width: '23em' }} fluid={images.hamburger.childImageSharp.fluid} />
      </RightBox>
    </Container>
  )
}

export default HomeHeaderBis

export const Container = styled.div`
  background-image: url(${props => props.backgroundLeft}),
    url(${props => props.backgroundRight}),
    radial-gradient(1982px 1982px at 49.27% 16.83%, #ffffff 0%, #cccbcb 100%);
  background-repeat: no-repeat no-repeat;
  background-size: 70%, 70%, cover;
  background-position: bottom left, top right;
  height: 45em;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 0.5em 0 4em 0;

  @media (max-width: ${breakpoints.l}px) {
    flex-direction: column;
    height: auto;
    padding: 5em 2em;
    background-size: 100%, 100%, cover;
  }
`

export const LeftBox = styled.div`
  width: 30em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: flex-end;

  @media (max-width: ${breakpoints.l}px) {
    width: 100%;
  }
`

export const CenterBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 15em;
  height: 90%;

  @media (max-width: ${breakpoints.l}px) {
    width: 100%;
    margin: 3em 0;
    flex-direction: row;
  }
`

export const Separator = styled.div`
  border-left: 2px solid #2b5c6b;
  height: 100%;

  @media (max-width: ${breakpoints.l}px) {
    border-top: 2px solid #2b5c6b;
    border-left: none;
    width: 70%;
  }
`

export const CircleBox = styled.div`
  width: 8em;
  height: 8em;
  padding: 0.5em;
  display: flex;
  margin: 1em;
  border-radius: 50%;
  justify-content: center;
  background-color: #95c11f;

  @media (max-width: ${breakpoints.l}px) {
    padding: 0 1em;
    height: 9em;
  }
  

  p {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: white;
    font-size: 1.2em;

    @media (max-width: ${breakpoints.l}px) {
      font-size: 1.1em;
    }
  }
`

export const RightBox = styled.div`
  width: 30em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: ${breakpoints.l}px) {
    width: 100%;
  }
`

export const TitleBox = styled.div``

export const Title = styled.p`
  font-size: 3em;
  color: ${props => (props.left ? '#2b5c6b' : 'black')};
  margin: 0;
  text-align: center;

  @media (max-width: ${breakpoints.l}px) {
    font-size: 2.5em;
    text-align: center;
    margin-bottom: 0.5em;
  }

  span {
    color: black;
    font-size: 1.5em;
  }
`

export const SubTitle = styled.p`
  color: #2b5c6b;
  font-size: 1.3em;
  margin: 0;
  text-align: center;

  span {
    color: #95c11f;
  }
`

export const Button = styled.a`
  border: 2px solid #2b5c6b;
  background-color: transparent;
  color: #2b5c6b;
  border-radius: 30px;
  width: fit-content;
  padding: 0.3em 2em;
  font-size: 1.1em;
  margin: 1em 0;
  text-decoration: none;

  :hover {
    border: 2px solid transparent;
    background-color: #2b5c6b;
    color: #eed7b8;
    cursor: pointer;
  }
`

export const Image = styled(Img)`
  width: 38em;

  @media (max-width: ${breakpoints.xl}px) {
    width: 27em;
  }

  @media (max-width: ${breakpoints.l}px) {
    width: 22em;
  }
`
