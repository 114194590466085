import styled from '@emotion/styled'
import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import React from 'react'
import bioIcon from '../../data/icons/home-page/bio-icon.png'
import CoffeeIcon from '../../data/icons/home-page/cafe-icon.png'
import CalandarIcon from '../../data/icons/home-page/calendrier-icon.png'
import { breakpoints } from '../../utils/styles'

const Block2 = () => {
  const images = useStaticQuery(graphql`
    query {
      background: file(
        relativePath: { eq: "images/home-page/background-block2.png" }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }

      coffee: file(
        relativePath: { eq: "images/home-page/cafe-img-block-2.jpg" }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  // console.log(images)

  return (
    <Container id='block2' img={images.background.childImageSharp.fluid.src}>
      <ImgWrapper>
        <Title>Le café de spécialité</Title>
        <CoffeeImg
          fluid={images.coffee.childImageSharp.fluid}
        />
      </ImgWrapper>
      <ContentWrapper
        data-aos='fade-left'
        data-aos-duration='3000'
        data-aos-delay='200'
      >
        <ItemWrapper>
          <IconWrapper>
            <Icon src={bioIcon} alt='jh&co' />
          </IconWrapper>
          <ItemText>
            JH and Co sélectionne ses grands crus auprès de petites coopératives
            BIO des 4 coins du monde.
          </ItemText>
        </ItemWrapper>

        <ItemWrapper>
          <IconWrapper>
            <Icon src={CoffeeIcon} alt='jh&co' />
          </IconWrapper>
          <ItemText>
            Sélection trés minitieuse des grains de sorte à ce que chaque grains
            soit d’exception
          </ItemText>
        </ItemWrapper>

        <ItemWrapper>
          <IconWrapper>
            <Icon src={CalandarIcon} alt='jh&co' />
          </IconWrapper>
          <ItemText>
            Nous valorisons également les circuits courts pour une meilleure
            traçabilité mais aussi aﬁn de garantir un approvisionnement local,
            une parfaite maitrise de la qualité de nos produits.
          </ItemText>
        </ItemWrapper>
      </ContentWrapper>
    </Container>
  )
}

export default Block2

export const Container = styled.div`
  position: relative;
  height: 57em;
  margin: 5em 10em 2em 10em;
  background-image: url('${props => props.img}') ;
  background-repeat: no-repeat;
  background-size: 80% ;
  background-position: 30% 85%;

  @media (max-width: ${breakpoints.xl}px) {
    background-size: 75% ;
    background-position: 28% 80%;
    margin: 5em 6em;
  }

  @media (max-width: ${breakpoints.l}px) {
    margin: 5em auto;
    background-image: none;
  }

  @media (max-width: ${breakpoints.s}px) {
    margin-top: 1em;
    margin-bottom: 10em;
    width: 95%;
  }
`

export const ImgWrapper = styled.div`
  align-self: flex-start;
  display: flex;
  flex-direction: column;
  width: 40em;
  margin: 0 0 0 3em;

  @media (max-width: ${breakpoints.l}px) {
    margin: 0 auto;
  }

  @media (max-width: ${breakpoints.s}px) {
    width: 100%;
    margin-bottom: 1em;
  }
`

export const Title = styled.h2`
  font-size: 2.7em;
  text-align: end;
  font-weight: 400;
  margin-right: 0.3em;
  display: block;

  @media (max-width: ${breakpoints.s}px) {
    text-align: center;
    margin: 0 1em;
    font-size: 2em;
  }

  @media (max-width: ${breakpoints.m}px) {
    margin: 1em auto;
  }
`

export const CoffeeImg = styled(Img)`
  border-radius: 20px;
`

export const ContentWrapper = styled.div`
  position: absolute;
  align-self: flex-end;
  width: 25em;
  height: 28em;
  background-color: #12282e;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 3em 5em;
  right: 4em;
  top: 30em;
  color: white;
  font-size: 0.9em;
  font-family: 'Avenir Light', serif;
  border-radius: 20px;

  @media (max-width: ${breakpoints.l}px) {
    left: 0;
    right: 0;
    margin: 0 auto;
    top: 35em;
  }

  @media (max-width: ${breakpoints.s}px) {
    top: unset;
    padding: 1em;
    margin: 0;
    width: fit-content;
    height: fit-content;
  }
`

export const TopText = styled.p`
  text-align: center;
  margin-bottom: 2em;
`

export const ItemWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-self: center;
  margin-bottom: 3em;

  @media (max-width: ${breakpoints.s}px) {
    flex-direction: column;
  }
`

export const ItemText = styled.p`
  display: flex;
  align-items: center;
  margin-left: 2em;

  @media (max-width: ${breakpoints.s}px) {
    margin: 0;
    text-align: center;
  }
`

export const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 10em;

  @media (max-width: ${breakpoints.s}px) {
    width: 100%;
    margin-bottom: 2em;
  }
`

export const Icon = styled.img`
  width: 5em;

  @media (max-width: ${breakpoints.s}px) {
    width: 5em;
  }
`
