import styled from '@emotion/styled'
import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import React from 'react'
import { breakpoints } from '../../utils/styles'

const Block0 = () => {
  const images = useStaticQuery(graphql`
    query {
      guys: file(relativePath: { eq: "images/home-page/guys.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }

      background: file(
        relativePath: { eq: "images/home-page/block-0/top-img.png" }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }

      logo: file(relativePath: { eq: "images/logo.png" }) {
        childImageSharp {
          fixed(width: 230) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  return (
    <Container>
      <Title>Nous connaître</Title>
      <GuysImgMobile fluid={images.guys.childImageSharp.fluid} />
      <BlueBox>
        <BackgroundImg fluid={images.background.childImageSharp.fluid} />
        <GuysImg
          data-aos='fade-left'
          data-aos-duration='3000'
          data-aos-delay='200'
        >
          <Img
            fluid={images.guys.childImageSharp.fluid}
          />
        </GuysImg>
        <ContentWrapper>
          <Logo fixed={images.logo.childImageSharp.fixed} />
          <TextContent>
            Jh & Co c’est deux frères qui ont soif d’entreprendre.
            <br /><br />
            L'un est riche en découvertes (culinaires, culturelles...) qu'il a fait au travers de ses nombreux voyages aux quatre coins du globe, l'autre est, quand à lui, passionné de cuisine, plus précisément de pâtisseries et autres gourmandises.
            <br /><br />
            Quelques années  pour murir le projet...et nous voilà avec l'envie de vous faire plaisir.
          </TextContent>
          <ButtonLink> {/* href='https://jh-co-clickandcollect.myshopify.com/collections/' */}
            Nos spécialités
          </ButtonLink>
        </ContentWrapper>
      </BlueBox>
    </Container>
  )
}

export default Block0

export const Container = styled.div`
  max-width: 1100px;
  width: 70%;
  margin: 0 auto;

  @media (max-width: ${breakpoints.xl}px) {
    width: 90%;
  }

  @media (max-width: ${breakpoints.s}px) {
    width: 100%;
  }
`

export const Title = styled.p`
  color: #2b5c6b;
  font-size: 2.6em;
  margin-bottom: 0.3em;

  @media (max-width: ${breakpoints.m}px) {
    text-align: center;
  }
`

export const BlueBox = styled.div`
  position: relative;
  padding: 4em 2em;
  height: max-content;
  border-radius: 20px;
  background-image: linear-gradient(
    to right,
    #13282e 0%,
    #13282e 0.20706700000000006%,
    #2a5b6b 100%
  );
  z-index: 0;

  @media (max-width: ${breakpoints.m}px) {
    height: auto;
    width: 70%;
    margin: -0.3em auto 0 auto;
    padding: 2em 2em;
  }

  @media (max-width: ${breakpoints.s}px) {
    width: 80%;
    margin: auto;
    padding: 2em 2em;
  }
`

export const BackgroundImg = styled(Img)`
  position: absolute !important;
  width: 70%;
  top: 0;
  left: 0;
  z-index: 2;
`

export const Logo = styled(Img)`
  z-index: 20;
`

export const GuysImg = styled.div`
  position: absolute !important;
  bottom: -0.5em;
  right: -7em;
  width: 36em;
  z-index: 10;

  @media (max-width: ${breakpoints.xl}px) {
    width: 31em;
    right: -2em;
  }

  @media (max-width: ${breakpoints.l}px) {
    display: none;
  }
`

export const GuysImgMobile = styled(Img)`
  width: 80%;
  margin: 2em auto 0 auto;
  z-index: 0;
  @media (min-width: ${breakpoints.l}px) {
    display: none;
  }
`

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 50%;
  height: 25em;

  @media (max-width: ${breakpoints.l}px) {
    margin: 6em auto 0 auto;
    height: 30em;
    width: 80%;
  }

  @media (max-width: ${breakpoints.m}px) {
    height: 28em;
    margin: 0 auto;
  }

  @media (max-width: ${breakpoints.s}px) {
    width: 100%;
    height: 30em;
  }
`

export const TextContent = styled.p`
  display: block;
  padding: 0 1em;
  font-family: 'Avenir Light';
  color: white;
  font-size: 1em;
  text-align: center;
  font-weight: 300;

  @media (max-width: ${breakpoints.s}px) {
    width: 100%;
    padding: 0;
  }
`

export const ButtonLink = styled.a`
  width: auto;
  background-color: #eed7b8;
  border-radius: 20px;
  color: #2b5c6b;
  padding: 0.3em 1em;
  font-size: 1.2em;
  border: 2px solid transparent;
  text-decoration: none;

  :hover {
    background-color: #2b5c6b;
    color: #eed7b8;
    cursor: pointer;
    border: 2px solid #eed7b8;
  }
`
