import styled from '@emotion/styled'
import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import React from 'react'
import bioIcon from '../../data/icons/home-page/bio-icon.png'
import BiscuitIcon from '../../data/icons/home-page/biscuit-icon.png'
import CalandarIcon from '../../data/icons/home-page/calendrier-icon.png'
import { breakpoints } from '../../utils/styles'

const Block3 = () => {
  const images = useStaticQuery(graphql`
    query {
      background: file(relativePath: { eq: "images/home-page/muffins.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }

      coffee: file(
        relativePath: { eq: "images/home-page/biscuit-maison.jpg" }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Container id='block3' img={images.background.childImageSharp.fluid.src}>
      <ImgWrapper>
        <Title>Produits fait maison</Title>
        <CoffeeImg fluid={images.coffee.childImageSharp.fluid} />
      </ImgWrapper>
      <ContentWrapper
        data-aos='fade-right'
        data-aos-duration='3000'
        data-aos-delay='200'
      >
        <ItemWrapper>
          <IconWrapper>
            <Icon src={bioIcon} alt='jh&co' />
          </IconWrapper>
          <ItemText>
            JH & Co sélectionne des produits issus del’agriculture biologique,
            ou labellisés Appellation d’Origine Protégée, Appellation d’Origine
            Contrôlée. Nous priorisons également les produits d'origine France
            et de saison.
          </ItemText>
        </ItemWrapper>

        <ItemWrapper>
          <IconWrapper>
            <Icon src={BiscuitIcon} alt='jh&co' />
          </IconWrapper>
          <ItemText>
            Chez JH & Co, vous dégustez des produits simples mais tellement
            bons! Nous élaborons nos propres recettes, uniques, parfois
            inédites.Nous recherchons l'excellence aﬁn de vous satisfaire.
          </ItemText>
        </ItemWrapper>

        <ItemWrapper>
          <IconWrapper>
            <Icon src={CalandarIcon} alt='jh&co' />
          </IconWrapper>
          <ItemText>
            Nous valorisons également les circuits courts pour une meilleure
            traçabilité mais aussi afin de garantir un approvisionnement local,
            une parfaite maitrise de la qualité de nos produits.
          </ItemText>
        </ItemWrapper>
        <Button href='https://jhnco-clickncollect.fr'>
          Click’n’Collect
        </Button>
      </ContentWrapper>
    </Container>
  )
}

export default Block3

export const Container = styled.div`
  position: relative;
  height: 74em;
  margin: 5em 5em;
  background-image: url('${props => props.img}') ;
  background-repeat: no-repeat;
  background-size: 38% ;
  background-position: 70% 95%;

  @media (max-width: ${breakpoints.xl}px) {
    background-size: 38% ;
    background-position: 89% 88%;
    margin: 5em 6em;
  }

  @media (max-width: ${breakpoints.l}px) {
    margin: 5em auto;
    background-image: none;
  }

  @media (max-width: ${breakpoints.m}px) {
   height: 68em;
  }

  @media (max-width: ${breakpoints.s}px) {
    margin-top: 0;
    margin-bottom: 0;
    height: 78em;
    width: 95%;
  }
`

export const ImgWrapper = styled.div`
  align-self: flex-start;
  display: flex;
  flex-direction: column;
  width: 50em;
  margin: 0 auto;

  @media (max-width: ${breakpoints.l}px) {
    margin: 0 auto;
    width: 85%;
  }

  @media (max-width: ${breakpoints.s}px) {
    width: 100%;
    margin-bottom: 1em;
  }
`

export const Title = styled.h2`
  font-size: 2.7em;
  text-align: end;
  font-weight: 400;
  margin-right: 0.3em;
  color: #2b5c6b;

  @media (max-width: ${breakpoints.m}px) {
    font-size: 2.5em;
  }

  @media (max-width: ${breakpoints.s}px) {
    display: block;
    text-align: center;
    padding: 0 0.5em;
    font-size: 1.7em;
  }

  @media (max-width: ${breakpoints.m}px) {
    margin: 1em auto;
  }
`

export const CoffeeImg = styled(Img)`
  border-radius: 20px;
`

export const ContentWrapper = styled.div`
  position: absolute;
  align-self: flex-end;
  width: 32em;
  height: 34em;
  background-color: #2b5c6b;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 2em 3em 2em 3em;
  left: 2em;
  top: 40em;
  color: white;
  font-size: 0.9em;
  font-family: 'Avenir Light', serif;
  border-radius: 20px;

  @media (max-width: ${breakpoints.l}px) {
    left: 0;
    right: 0;
    margin: 0 auto;
    top: 40em;
  }

  @media (max-width: ${breakpoints.m}px) {
    top: 35em;
    width: 26em;
    height: auto;
  }

  @media (max-width: ${breakpoints.s}px) {
    top: unset;
    padding: 2em 1em;
    margin: 0;
    width: fit-content;
    height: fit-content;
  }
`

export const ItemWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-self: center;
  margin-bottom: 3em;

  @media (max-width: ${breakpoints.s}px) {
    flex-direction: column;
  }
`

export const ItemText = styled.p`
  display: flex;
  align-items: center;
  margin-left: 2em;

  @media (max-width: ${breakpoints.s}px) {
    margin: 0;
    text-align: center;
  }
`

export const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 10em;

  @media (max-width: ${breakpoints.s}px) {
    width: 100%;
    margin-bottom: 2em;
  }
`

export const Icon = styled.img`
  width: 5em;

  @media (max-width: ${breakpoints.s}px) {
    width: 5em;
  }
`

export const Button = styled.a`
  border: 2px solid transparent;
  background-color: #eed7b8;
  border-radius: 30px;
  width: 55%;
  height: 1.9em;
  color: #2b5c6b;
  font-size: 1.3em;
  font-family: 'Krona One', serif;
  text-decoration: none;
  text-align: center;
  line-height: 1.9;
  border: 2px solid transparent;

  @media (hover: hover) {
    :hover {
      background-color: transparent;
      color: #eed7b8;
      border: 2px solid #eed7b8;
      cursor: pointer;
    }
  }
`
