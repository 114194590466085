import styled from '@emotion/styled'
import { Link } from 'gatsby'
import React from 'react'
import barMobile from '../../data/icons/home-page/bar-mobile.png'
import coffeeShop from '../../data/icons/home-page/coffee-shop.png'
import foodTruck from '../../data/icons/home-page/food-truk.png'
import tukTuk from '../../data/icons/home-page/tuk-tuk.png'
import { breakpoints } from '../../utils/styles'

const Block4 = () => {
  return (
    <Container>
      <Title>Nos Moyens</Title>
      <SubTitle>
        Au fil des années, nous avons développé différents modules de vente.
        Ainsi, JH & CO a su s'adapter aux différentes exigences des modes de
        consommations et aux besoins des clients.
      </SubTitle>
      <Wrapper>
        <ContentItemBox
          background='none'
          data-aos='fade-right'
          data-aos-duration='3000'
          data-aos-delay='200'
        >
          <IconBox>
            <Icon style={{ width: '11em' }} src={tukTuk} />
          </IconBox>
          <ItemTitle>
            Coffee Truck
            <br />
            <span>(structure ambulante)</span>
          </ItemTitle>
          <ItemText marginTop>
            Nous sommes littéralement tombés sous le charme de ce véhicule au
            design à lafois intemporel, vintage, et urbain. Il déclenche
            toujours autant d’émotions. Une attraction à lui seul! la star c’est
            lui.
          </ItemText>
        </ContentItemBox>

        <ContentItemBox
          style={{ borderTopRightRadius: '20px' }}
          background='#2b5c6b'
          color='true'
          data-aos='fade-left'
          data-aos-duration='3000'
          data-aos-delay='200'
        >
          <IconBox>
            <Icon style={{ width: '14em' }} src={foodTruck} />
          </IconBox>
          <ItemTitle color='true'>Corner roulant</ItemTitle>
          <ItemText>
            Copie conforme du Piaggio APE, il est tout aussi canon ! Il a
            l'avantage d'être non motorisé, ce qui permet un positionnement en
            intérieur ( galeries commerciales, gares,hall...). Il répond au
            normes de sécurité.
          </ItemText>
        </ContentItemBox>

        <ContentItemBox
          style={{ borderBottomLeftRadius: '20px' }}
          background='#12282e'
          color='true'
          data-aos='fade-right'
          data-aos-duration='3000'
          data-aos-delay='200'
        >
          <IconBox>
            <Icon style={{ width: '9em' }} src={barMobile} />
          </IconBox>
          <ItemTitle color='true'>
            Coffee Shop
            <br />
            <span>(Privatisation)</span>
          </ItemTitle>
          <ItemText>
            A la demande de notre clientèle ,habituée jusqu'ici à notre concept
            "nomade", voici notre première boutique ! La suite logique... ce lieu cosy ...
          </ItemText>
        </ContentItemBox>

        <ContentItemBox
          background='none'
          data-aos='fade-left'
          data-aos-duration='3000'
          data-aos-delay='200'
        >
          <IconBox>
            <Icon style={{ width: '10em' }} src={coffeeShop} />
          </IconBox>
          <ItemTitle>Bar Mobile</ItemTitle>
          <ItemText marginTop>
            Bar nomade et non motorisé. Il nous permet une installation
            rapide,facile, écologique, et peu encombrante.
          </ItemText>
        </ContentItemBox>
      </Wrapper>

      <ButtonBox>
        <Button to='/cote-pro'>Coté Pro</Button>
        <ButtonCollect href='https://jhnco-clickncollect.fr'>
          Click’n’Collect
        </ButtonCollect>
      </ButtonBox>
    </Container>
  )
}

export default Block4

export const Container = styled.div`
  margin: 3em 0;

  @media (max-width: ${breakpoints.s}px) {
    margin-top: 0;
  }
`

export const Title = styled.h2`
  text-align: center;
  color: #2b5c6b;
  font-size: 3em;
  font-weight: 400;
  margin: 1em 0 0.5em 0;

  @media (max-width: ${breakpoints.s}px) {
    font-size: 1.7em;
  }
`

export const SubTitle = styled.p`
  font-family: 'Avenir Light', sans-serif;
  font-size: 1.1em;
  text-align: center;
  display: block;
  width: 40em;
  max-width: 90%;
  margin: 0 auto;
`

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin: 3em 10em 3em 10em;

  @media (max-width: ${breakpoints.xl}px) {
    margin: 3em 2em 3em 2em;
  }

  @media (max-width: ${breakpoints.l}px) {
    margin: 3em 1em 3em 1em;
  }
`

export const ContentItemBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${props => props.background};
  color: ${props => (props.color ? 'white' : 'black')};
  width: 35%;
  height: 25em;
  padding: 2em 3em;

  @media (max-width: ${breakpoints.l - 1}px) {
    :nth-of-type(2) {
      order: 2;
    }
  }

  @media (max-width: ${breakpoints.l}px) {
    width: 60%;
    border-radius: 20px;
  }

  @media (max-width: ${breakpoints.s}px) {
    width: 90%;
  }
`

export const IconBox = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
`

export const Icon = styled.img``

export const ItemTitle = styled.p`
  font-size: 1.5em;
  text-align: center;
  font-weight: 400;
  color: ${props => (props.color ? '#eed7b8' : '#2b5c6b')};

  span {
    font-size: 0.7em;
  }
`

export const ItemText = styled.p`
  font-family: 'Avenir Light', serif;
  font-weight: 300;
  text-align: center;
  margin-top: 0;
`

export const ButtonBox = styled.div`
  width: 33em;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media (max-width: ${breakpoints.s}px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
`

export const ButtonCollect = styled.a`
  border: 2px solid transparent;
  border-radius: 30px;
  width: 13em;
  height: 2em;
  font-size: 1.2em;
  font-weight: 400;
  font-family: 'Krona One', serif;
  color: #2b5c6b;
  background-color: #eed7b8;
  text-decoration: none;
  text-align: center;
  line-height: 2;

  @media (max-width: ${breakpoints.s}px) {
    margin-bottom: 1em;
  }

  @media (hover: hover) {
    :hover {
      background-color: white;
      border: 2px solid #eed7b8;
      color: '#2b5c6b;
      cursor: pointer;
    }
  }
`

export const Button = styled(Link)`
  border: 2px solid transparent;
  border-radius: 30px;
  width: 13em;
  height: 2em;
  font-size: 1.2em;
  font-weight: 400;
  font-family: 'Krona One', serif;
  color: #eed7b8;
  background-color: #2b5c6b;
  text-decoration: none;
  text-align: center;
  line-height: 2;

  @media (max-width: ${breakpoints.s}px) {
    margin-bottom: 1em;
  }

  @media (hover: hover) {
    :hover {
      background-color: white;
      border: 2px solid #2b5c6b;
      color: #2b5c6b;
      cursor: pointer;
    }
  }
`
