import styled from '@emotion/styled'
import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import React from 'react'
import { breakpoints } from '../../utils/styles'
import downArrow from './../../data/icons/arrow-down.png'

const Block1 = () => {
  const image = useStaticQuery(graphql`
    query {
      cofee: file(relativePath: { eq: "images/home-page/cafe-img.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }

      house: file(relativePath: { eq: "images/home-page/fait-maison.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Container>
      <Title>Les spécialités JH & Co</Title>
      <BoxWrapper>
        <Box
          data-aos='fade-right'
          data-aos-duration='3000'
          data-aos-delay='200'
          cofeeImg={image.cofee.childImageSharp.fluid.src}
        >
          <BoxTitle>Le café de spécialité</BoxTitle>
          <BoxText>
            JH & Co sélectionne ses grands crus auprès de petites coopératives
            bio et “équitables” situées aux quatres coins du globe, en veillant
            à ce que chaque grain conserve ses qualités d’exception.
          </BoxText>
          <a href='#block2'>
            <BoxIcon src={downArrow} />
          </a>
        </Box>

        <Box
          data-aos='fade-left'
          data-aos-duration='3000'
          data-aos-delay='400'
          houseImg={image.house.childImageSharp.fluid.src} house
        >
          <BoxTitle>Produits fait maison</BoxTitle>
          <BoxText>
            Chez JH & Co Nous élaborons nos recettes et fabriquons nous mêmes la
            totalité de nos gourmandises salées et sucrées au sein de notre
            atelier. <br /> <br /> Passionnés de pâtisserie,nous attachons une grande
            importance à la qualité de nos produits, a fortiori aux choix des
            matières premières que nous utilisons.
          </BoxText>
          <a href='#block3'>
            <BoxIcon src={downArrow} />
          </a>
        </Box>
      </BoxWrapper>
    </Container>
  )
}

export default Block1

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const BackgroundImg = styled(Img)`
  top: 0;
  margin: 0 auto;
  width: 92%;
`

export const Title = styled.h2`
  margin: 2.2em auto 0 auto;
  display: block;
  color: #12282e;
  text-align: center;
  font-size: 3em;
  font-weight: 400;

  @media (max-width: ${breakpoints.s}px) {
    margin: 1.5em 1em;
    font-size: 2em;
  }

  @media (max-width: 321px) {
    font-size: 1.2em;
  }
`

export const BoxWrapper = styled.div`
  margin-top: 4em;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 42em;
  width: 55em;

  @media (max-width: ${breakpoints.m}px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: auto;
    width: 90%;
  }

  @media (max-width: ${breakpoints.s}px) {
    width: 95%;
    margin-top: 0;
  }

  @media (max-width: 321px) {
    width: 100%;
  }
`

export const Box = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  height: 35em;
  width: 24em;
  z-index: 2;
  background: ${props => (props.house ? '#2b5c6b' : '#12282e')};
  background-image: ${props =>
    props.house
      ? 'linear-gradient(180deg, rgba(43,92,107,0) 26%, rgba(43,92,107,1) 50%)'
      : 'linear-gradient(180deg, rgba(18,40,46,0) 26%, rgba(18,40,46,1) 50%)'}, url('${props =>
  props.house ? props.houseImg : props.cofeeImg}');
    background-repeat: no-repeat, no-repeat;
    background-size: 100% ,100% 50%;
  align-self: ${props => (props.house ? 'flex-end' : 'flex-start')};
  padding: 0 1em 2em 1em;
  border-radius: 20px;

  @media (max-width: ${breakpoints.m}px) {
    align-self: auto;
    margin-bottom: 2em;
    height: 33em;
    padding-bottom: 2em;
  }

  @media (max-width: ${breakpoints.s}px) {
    width: fit-content;
    height: 38em;
    margin-bottom: 1em;
  }

  @media (max-width: 321px) {
    width: 80%;
    height: 35em;
  }
`

export const BoxImg = styled(Img)`
  position: absolute !important;
  top: 0;
  width: 100% !important;
  border-radius: 20px 20px 0 0;
  z-index: 0;
`

export const BoxTitle = styled.p`
  color: #eed7b8;
  font-size: 1.4em;
  margin: 0.8em 0 0.5em 0;
  text-align: center;
`

export const BoxText = styled.p`
  color: white;
  font-family: 'Avenir Light', serif;
  text-align: center;
  font-size: 0.9em;
  margin: 1em 0;

  @media (max-width: ${breakpoints.s}px) {
    width: 90%;
  }
`

export const BoxIcon = styled.img`
  width: 3em;
  margin-top: 1em;
`
